
// Live
const baseURL = "https://entry.lo-vietnam.com/api/v1";
const baseURLNoPort = "http://134.209.97.124";
const basePort = 5978;

// Test
// const baseURL = "http://192.168.1.15:3000/api/v1";
// const baseURLNoPort = "http://192.168.1.15";
// const basePort = 3000;



export const provider = {
    baseURL: baseURL,
    basePort: basePort,
    baseURLNoPort: baseURLNoPort,
    token:"naronglts",
}