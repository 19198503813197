export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "Lo0800": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["08:00 AM"])},
  "Lo0900": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09:00 AM"])},
  "Lo1000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10:00 AM"])},
  "Lo1100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11:00 AM"])},
  "Lo1200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12:00 PM"])},
  "Lo1300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13:00 PM"])},
  "Lo1400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14:00 PM"])},
  "Lo1500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15:00 PM"])},
  "Lo1600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16:00 PM"])},
  "Lo1700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17:00 PM"])},
  "Lo1800": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18:00 PM"])},
  "Lo1900": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19:00 PM"])},
  "Lo2000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20:00 PM"])},
  "Lo2100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["21:00 PM"])},
  "Lo2200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22:00 PM"])},
  "Lo2300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23:00 PM"])},
  "Lo0000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["00:00 AM"])},
  "Lo0100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["01:00 AM"])},
  "Lo0200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["02:00 AM"])},
  "Lo0300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["03:00 AM"])},
  "Lo0400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["04:00 AM"])},
  "Lo0500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["05:00 AM"])},
  "Lo0600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["06:00 AM"])},
  "Lo0700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["07:00 AM"])}
}